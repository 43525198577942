import { takeLatest, put } from 'redux-saga/effects';
import { STATUS, API, jsonToQueryString } from '@vezeeta/web-utils';
import CoustomUrls from '../../urls';
import {
  ENTITIES,
  loadEntitiesPerCountryDone,
  loadEntitiesPerCountryFail,
  loadEntityStructureDone,
  loadEntityStructureFail,
} from '../actions/entities';

function* LoadEntities(action) {
  const body = {
    countryId: action.CountryId,
  };

  const apiWrapper = yield new API();
  const response = yield apiWrapper.get(
    `${CoustomUrls.getEntitiesByCountryId}?${jsonToQueryString(body)}`,
    null,
    false,
  );
  if (STATUS.isSuccess(response.status)) {
    yield put(loadEntitiesPerCountryDone(response.data.ReturnObject));
  } else {
    yield put(loadEntitiesPerCountryFail(response));
  }
}

function* LoadEntityStructure(action) {
  const body = {
    entityKey: action.EntityKey,
  };

  const apiWrapper = yield new API();
  const response = yield apiWrapper.get(
    `${CoustomUrls.getEntityStructure}?${jsonToQueryString(body)}`,
    null,
    false,
  );
  if (STATUS.isSuccess(response.status)) {
    yield put(loadEntityStructureDone(response.data));
  } else {
    yield put(loadEntityStructureFail(response));
  }
}

function* entitiesSaga() {
  yield takeLatest(ENTITIES.ENTITIES.LOAD, LoadEntities);
  yield takeLatest(ENTITIES.ENTITY_STRUCTURE.LOAD, LoadEntityStructure);
}

export default entitiesSaga;
