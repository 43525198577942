export const USERS = {
  ADD_USER: {
    LOAD: 'ADD_USER_LOAD',
    SUCCEEDED: 'ADD_USER_SUCCEEDED',
    FAILED: 'ADD_USER_FAILED',
  },
  FETCH_USER_DETAILS: {
    LOAD: 'FETCH_USER_DETAILS_LOAD',
    SUCCEEDED: 'FETCH_USER_DETAILS_SUCCEEDED',
    FAILED: 'FETCH_USER_DETAILS_FAILED',
  },
  FETCH_USERS: {
    LOAD: 'FETCH_USERS_LOAD',
    SUCCEEDED: 'FETCH_USERS_SUCCEEDED',
    FAILED: 'FETCH_USERS_FAILED',
  },
  MODAL: {
    TOGGLE_DELETE: 'DELETE_MODAL',
    TOGGLE_ADD: 'ADD_MODAL',
  },
  HIDE_SNACK_BAR: 'USER_HIDE_SNACK_BAR',
};

export const addUser = userDetails => ({
  type: USERS.ADD_USER.LOAD,
  userDetails,
});

export const addUserSucceeded = () => ({
  type: USERS.ADD_USER.SUCCEEDED,
});

export const addUserFailed = () => ({
  type: USERS.ADD_USER.FAILED,
});

export const getUserDetails = () => ({
  type: USERS.FETCH_USER_DETAILS.LOAD,
});

export const getUserDetailsSucceeded = userDetails => ({
  type: USERS.FETCH_USER_DETAILS.SUCCEEDED,
  userDetails,
});

export const getUserDetailsFailed = () => ({
  type: USERS.FETCH_USER_DETAILS.FAILED,
});

export const getEntityUsers = entityKey => ({
  type: USERS.FETCH_USERS.LOAD,
  entityKey,
});

export const getEntityUsersSucceeded = users => ({
  type: USERS.FETCH_USERS.SUCCEEDED,
  users,
});

export const getEntityUsersFailed = () => ({
  type: USERS.FETCH_USERS.FAILED,
});

export const toggleAddUserModal = showModal => ({
  type: USERS.MODAL.TOGGLE_ADD,
  showModal,
});

export const hideSnackBar = () => ({
  type: USERS.HIDE_SNACK_BAR,
});
