import { fork } from 'redux-saga/effects';

import country from './country';
import entitiesSaga from './entities';
import usersSaga from './users';
import configsSaga from './configs';

const sagas = [country, entitiesSaga, usersSaga, configsSaga];

export default function* rootSaga() {
  yield sagas.map(saga => fork(saga));
}
