export const CONFIGS = {
  FETCH_VEZEETA_CONFIG: {
    LOAD: 'FETCH_VEZEETA_CONFIG_LOAD',
    SUCCEEDED: 'FETCH_VEZEETA_CONFIG_SUCCEEDED',
    FAILED: 'FETCH_VEZEETA_CONFIG_FAILED',
  },
  CHANGE_BRANCH_VEZEETA_CONFIG_TOKEN: {
    LOAD: 'CHANGE_BRANCH_VEZEETA_CONFIG_TOKEN_LOAD',
    SUCCEEDED: 'CHANGE_BRANCH_VEZEETA_CONFIG_TOKEN_SUCCEEDED',
    FAILED: 'CHANGE_BRANCH_VEZEETA_CONFIG_TOKEN_FAILED',
    CHANGE: 'CHANGE_BRANCH_VEZEETA_CONFIG_TOKEN_CHANGE',
  },
  ADD_EDIT_VEZEETA_CONFIG: {
    LOAD: 'ADD_EDIT_VEZEETA_CONFIG_LOAD',
    SUCCEEDED: 'ADD_EDIT_VEZEETA_CONFIG_SUCCEEDED',
    FAILED: 'ADD_EDIT_VEZEETA_CONFIG_FAILED',
  },
  ADD_EDIT_USER_CONFIG: {
    LOAD: 'ADD_EDIT_USER_CONFIG_LOAD',
    SUCCEEDED: 'ADD_EDIT_USER_CONFIG_SUCCEEDED',
    FAILED: 'ADD_EDIT_USER_CONFIG_FAILED',
  },
  FETCH_USER_CONFIGS: {
    LOAD: 'FETCH_USER_CONFIGS_LOAD',
    SUCCEEDED: 'FETCH_USER_CONFIGS_SUCCEEDED',
    FAILED: 'FETCH_USER_CONFIGS_FAILED',
  },
  FETCH_CONFIGS: {
    LOAD: 'FETCH_CONFIGS_LOAD',
    SUCCEEDED: 'FETCH_CONFIGS_SUCCEEDED',
    FAILED: 'FETCH_CONFIGS_FAILED',
  },
  MODAL: {
    TOGGLE_REGENERTE_TOKEN: 'REGENERTE_TOKEN_MODAL',
    TOGGLE_ADD_USER_CONFIG: 'ADD_USER_CONFIG_MODAL',
  },
};

export const getVezeetaConfig = () => ({
  type: CONFIGS.FETCH_VEZEETA_CONFIG.LOAD,
});

export const getVezeetaConfigSucceeded = vezeetaConfigObj => ({
  type: CONFIGS.FETCH_VEZEETA_CONFIG.SUCCEEDED,
  vezeetaConfigObj,
});

export const getVezeetaConfigFailed = () => ({
  type: CONFIGS.FETCH_VEZEETA_CONFIG.FAILED,
});

export const regenerateVezeetaConfigTokenPerBranch = branchKey => ({
  type: CONFIGS.CHANGE_BRANCH_VEZEETA_CONFIG_TOKEN.LOAD,
  branchKey,
});

export const regenerateVezeetaConfigTokenPerBranchSucceeded = vezeetaConfigObj => ({
  type: CONFIGS.CHANGE_BRANCH_VEZEETA_CONFIG_TOKEN.SUCCEEDED,
  vezeetaConfigObj,
});

export const regenerateVezeetaConfigTokenPerBranchFailed = () => ({
  type: CONFIGS.CHANGE_BRANCH_VEZEETA_CONFIG_TOKEN.FAILED,
});

export const changeBranchToken = () => ({
  type: CONFIGS.CHANGE_BRANCH_VEZEETA_CONFIG_TOKEN.CHANGE,
});

export const addEditVezeetaConfig = vezeetaConfigObj => ({
  type: CONFIGS.ADD_EDIT_VEZEETA_CONFIG.LOAD,
  vezeetaConfigObj,
});

export const addEditVezeetaConfigSucceeded = vezeetaConfigObj => ({
  type: CONFIGS.ADD_EDIT_VEZEETA_CONFIG.SUCCEEDED,
  vezeetaConfigObj,
});

export const addEditVezeetaConfigFailed = () => ({
  type: CONFIGS.ADD_EDIT_VEZEETA_CONFIG.FAILED,
});

export const addEditUserConfig = userConfigObj => ({
  type: CONFIGS.ADD_EDIT_USER_CONFIG.LOAD,
  userConfigObj,
});

export const addEditUserConfigSucceeded = userConfigObj => ({
  type: CONFIGS.ADD_EDIT_USER_CONFIG.SUCCEEDED,
  userConfigObj,
});

export const addEditUserConfigFailed = () => ({
  type: CONFIGS.ADD_EDIT_USER_CONFIG.FAILED,
});

export const fetchUserConfigs = () => ({
  type: CONFIGS.FETCH_USER_CONFIGS.LOAD,
});

export const fetchUserConfigsSucceeded = userConfigs => ({
  type: CONFIGS.FETCH_USER_CONFIGS.SUCCEEDED,
  userConfigs,
});

export const fetchUserConfigsFailed = () => ({
  type: CONFIGS.FETCH_USER_CONFIGS.FAILED,
});

export const fetchConfigs = entitykey => ({
  type: CONFIGS.FETCH_CONFIGS.LOAD,
  entitykey,
});

export const fetchConfigsSucceeded = configs => ({
  type: CONFIGS.FETCH_CONFIGS.SUCCEEDED,
  configs,
});

export const fetchConfigsFailed = () => ({
  type: CONFIGS.FETCH_CONFIGS.FAILED,
});

export const toggleRegenerateTokenModal = showModal => ({
  type: CONFIGS.MODAL.TOGGLE_REGENERTE_TOKEN,
  showModal,
});

export const toggleAddUserConfigModal = showModal => ({
  type: CONFIGS.MODAL.TOGGLE_ADD_USER_CONFIG,
  showModal,
});
