import { takeEvery, put, select } from 'redux-saga/effects';
import { STATUS, API, jsonToQueryString } from '@vezeeta/web-utils';
import Cookies from 'js-cookie';

import CoustomUrls from '../../urls';

import {
  CONFIGS,
  getVezeetaConfigSucceeded,
  getVezeetaConfigFailed,
  addEditVezeetaConfigSucceeded,
  addEditVezeetaConfigFailed,
  fetchConfigsSucceeded,
  fetchConfigsFailed,
  fetchUserConfigsSucceeded,
  fetchUserConfigsFailed,
  fetchUserConfigs,
  regenerateVezeetaConfigTokenPerBranchSucceeded,
  regenerateVezeetaConfigTokenPerBranchFailed,
  changeBranchToken,
  addEditUserConfigSucceeded,
  addEditUserConfigFailed,
} from '../actions/configs';

const authKey = Cookies.get('AUTH_TOKEN');
const headers = [
  {
    key: 'Authorization',
    value: `bearer ${authKey}`,
  },
];

function* getVezeetaConfig() {
  const apiObj = new API();
  const res = yield apiObj.get(CoustomUrls.vezeetaConfig, headers, false);
  if (STATUS.isSuccess(res.status)) {
    yield put(getVezeetaConfigSucceeded(res.response.returnObject));
  } else if (STATUS.isNotAuthorized(res.status)) {
    Cookies.remove('AUTH_TOKEN');
    window.location.pathname = '/';
  } else {
    yield put(getVezeetaConfigFailed());
  }
}

function* regenerateVezeetaConfigTokenPerBranch(action) {
  const apiObj = new API();
  const body = {
    branchKey: action.branchKey,
  };
  const res = yield apiObj.post(CoustomUrls.vezeetaConfig, body, headers);
  if (STATUS.isSuccess(res.status)) {
    yield [
      put(regenerateVezeetaConfigTokenPerBranchSucceeded(res.response.returnObject)),
      put(changeBranchToken()),
    ];
  } else {
    yield put(regenerateVezeetaConfigTokenPerBranchFailed());
  }
}

function* changeUpdatedBranchToken() {
  const state = yield select(state => state.configs); // eslint-disable-line
  const newBranchAuth = state.vezeetaConfig.branchAuth.map(branch => {
    if (branch.branchKey === state.regerenatedToken.branchKey) {
      return { ...branch, auth: state.regerenatedToken.auth };
    }
    return branch;
  });
  yield put(getVezeetaConfigSucceeded({ ...state.vezeetaConfig, branchAuth: newBranchAuth }));
}

function* addEditVezeetaConfig(action) {
  const body = action.vezeetaConfigObj;
  const apiObj = new API();
  const res = yield apiObj.put(CoustomUrls.vezeetaConfig, body, headers, false);
  if (STATUS.isSuccess(res.status)) {
    yield put(addEditVezeetaConfigSucceeded(res.response.returnObject));
  } else {
    yield put(addEditVezeetaConfigFailed());
  }
}

function* addEditUserConfig(action) {
  const body = action.userConfigObj;
  const apiObj = new API();
  const res = yield apiObj.post(CoustomUrls.entityConfigs, body, headers);
  if (STATUS.isSuccess(res.status)) {
    yield [put(addEditUserConfigSucceeded(res.response.returnObject)), put(fetchUserConfigs())];
  } else {
    yield put(addEditUserConfigFailed());
  }
}

function* getUserConfigs() {
  const apiObj = new API();
  const res = yield apiObj.get(CoustomUrls.userConfigs, headers, false);
  if (STATUS.isSuccess(res.status)) {
    yield put(fetchUserConfigsSucceeded(res.response.returnObject.branchConfigs));
  } else {
    yield put(fetchUserConfigsFailed());
  }
}

function* fetchConfigs(action) {
  const body = {
    entityKey: action.entitykey,
  };
  const apiObj = new API();
  const res = yield apiObj.get(
    `${CoustomUrls.entityConfigs}?${jsonToQueryString(body)}`,
    headers,
    false,
  );
  if (STATUS.isSuccess(res.status)) {
    yield put(fetchConfigsSucceeded(res.response.returnObject));
  } else {
    yield put(fetchConfigsFailed());
  }
}

export default function* configsSaga() {
  yield takeEvery(CONFIGS.FETCH_CONFIGS.LOAD, fetchConfigs);
  yield takeEvery(CONFIGS.FETCH_USER_CONFIGS.LOAD, getUserConfigs);
  yield takeEvery(CONFIGS.FETCH_VEZEETA_CONFIG.LOAD, getVezeetaConfig);
  yield takeEvery(CONFIGS.ADD_EDIT_VEZEETA_CONFIG.LOAD, addEditVezeetaConfig);
  yield takeEvery(
    CONFIGS.CHANGE_BRANCH_VEZEETA_CONFIG_TOKEN.LOAD,
    regenerateVezeetaConfigTokenPerBranch,
  );
  yield takeEvery(CONFIGS.CHANGE_BRANCH_VEZEETA_CONFIG_TOKEN.CHANGE, changeUpdatedBranchToken);
  yield takeEvery(CONFIGS.ADD_EDIT_USER_CONFIG.LOAD, addEditUserConfig);
}
