export const ENTITIES = {
  ENTITIES: {
    LOAD: 'ENTITIES_LOAD',
    LOAD_DONE: 'ENTITIES_LOAD_DONE',
    LOAD_FAIL: 'ENTITIES_LOAD_FAIL',
  },
  ENTITY_STRUCTURE: {
    LOAD: 'ENTITY_STRUCTURE_LOAD',
    LOAD_DONE: 'ENTITY_STRUCTURE_LOAD_DONE',
    LOAD_FAIL: 'ENTITY_STRUCTURE_LOAD_FAIL',
  },
};

export const loadEntitiesPerCountry = CountryId => ({
  type: ENTITIES.ENTITIES.LOAD,
  CountryId,
});

export const loadEntitiesPerCountryDone = entities => ({
  type: ENTITIES.ENTITIES.LOAD_DONE,
  entities,
});

export const loadEntitiesPerCountryFail = err => ({
  type: ENTITIES.ENTITIES.LOAD_FAIL,
  err,
});

export const loadEntityStructure = EntityKey => ({
  type: ENTITIES.ENTITY_STRUCTURE.LOAD,
  EntityKey,
});

export const loadEntityStructureDone = entityStructure => ({
  type: ENTITIES.ENTITY_STRUCTURE.LOAD_DONE,
  entityStructure,
});

export const loadEntityStructureFail = err => ({
  type: ENTITIES.ENTITY_STRUCTURE.LOAD_FAIL,
  err,
});
