/* global process */
import React, { PureComponent } from "react";
import Loadable from "react-loadable";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import {
  Loading,
  Spinner,
  SetGlobalStyle,
} from "@vezeeta/web-components";
import Cookies from "js-cookie";
import { Box } from "grid-styled";
import { Provider } from "react-redux";
import "@vezeeta/enterprise-modals/lib/Modal.scss";
import store from "../store";
import "./App.scss";

// Async loading components

const Login = Loadable({
  loader: () => import("./login/Login"),
  loading: Loading
});


const Dashboard = Loadable({
  loader: () => import("./dashboard/DashboardContainer"),
  loading: Loading
});

const routes = [
  {
    component: Login,
    route: "/login"
  },
  {
    component: Dashboard,
    route: "/book"
  }

];

class App extends PureComponent {
  constructor(props) {
    super(props);

    SetGlobalStyle();

    this.state = {
      isLoading: false
    };
  }

  componentDidMount() {
  }

  

  render() {
    let body;
    if (!this.state.isLoading) {
      body = (
        <Box>
          <Switch>
            {routes.map(route => (
              <Route
                key={route.route}
                path={route.route}
                component={route.component}
              />
            ))}
            <Redirect to="/login" />
          </Switch>
        </Box>
      );
    } else {
      body = (
        <div className="loading-routes">
          <Spinner />
        </div>
      );
    }

    return (
      <Provider store={store}>
        <Router history={this.browserHistory}>
          <div className="App">

            {body}
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
