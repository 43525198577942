import { combineReducers } from 'redux';

import country from './country';
import entities from './entities';
import users from './users';
import configs from './configs';

export default combineReducers({
  country,
  entities,
  users,
  configs,
});
