import { takeEvery, put } from 'redux-saga/effects';
import { STATUS, API, jsonToQueryString } from '@vezeeta/web-utils';
import Cookies from 'js-cookie';

import CoustomUrls from '../../urls';

import {
  USERS,
  addUserSucceeded,
  addUserFailed,
  getUserDetailsSucceeded,
  getUserDetailsFailed,
  getEntityUsers,
  getEntityUsersSucceeded,
  getEntityUsersFailed,
} from '../actions/users';

const authKey = Cookies.get('AUTH_TOKEN');
const headers = [
  {
    key: 'Authorization',
    value: `bearer ${authKey}`,
  },
];

function* addUser(action) {
  const apiObj = new API();
  const body = action.userDetails;
  const res = yield apiObj.post(CoustomUrls.user, body, headers);
  if (STATUS.isSuccess(res.status)) {
    yield [put(addUserSucceeded()), put(getEntityUsers(body.entityKey))];
  } else {
    yield put(addUserFailed());
  }
}

function* getUserDetails() {
  const apiObj = new API();
  const res = yield apiObj.get(CoustomUrls.user, headers, false);
  if (STATUS.isSuccess(res.status)) {
    yield put(getUserDetailsSucceeded(res.response.returnObject));
  } else if (STATUS.isNotAuthorized(res.status)) {
    Cookies.remove('AUTH_TOKEN');
    window.location.pathname = '/';
  } else {
    yield put(getUserDetailsFailed());
  }
}

function* fetchUsers(action) {
  const body = {
    entityKey: action.entityKey,
  };
  const apiObj = new API();
  const res = yield apiObj.get(
    `${CoustomUrls.getEntityUsers}?${jsonToQueryString(body)}`,
    headers,
    false,
  );
  if (STATUS.isSuccess(res.status)) {
    yield put(getEntityUsersSucceeded(res.response.returnObject));
  } else {
    yield put(getEntityUsersFailed());
  }
}

export default function* usersSaga() {
  yield takeEvery(USERS.ADD_USER.LOAD, addUser);
  yield takeEvery(USERS.FETCH_USER_DETAILS.LOAD, getUserDetails);
  yield takeEvery(USERS.FETCH_USERS.LOAD, fetchUsers);
}
