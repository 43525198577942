import { CONFIGS } from '../actions/configs';

const initialState = {
  vezeetaConfig: {
    configKey: undefined,
    url: undefined,
    branchAuth: [],
    state: undefined,
  },
  regerenatedToken: {
    branchKey: undefined,
    auth: undefined,
    state: undefined,
  },
  userConfigs: {
    list: [],
    filteredList: [],
    state: undefined,
  },
  addEditUserConfig: {
    configKey: undefined,
    url: undefined,
    authToken: undefined,
    branchKeys: [],
    state: undefined,
  },
  configs: {
    list: [],
    filteredList: [],
    state: undefined,
  },
  regenerateTokenModal: {
    show: false,
    tokenDetails: {},
  },
  addUserConfigModal: { show: false },
};

export default (state = initialState, { type, ...payload }) => {
  switch (type) {
    case CONFIGS.FETCH_VEZEETA_CONFIG.LOAD:
      return {
        ...state,
        vezeetaConfig: {
          configKey: undefined,
          url: undefined,
          branchAuth: [],
          state: CONFIGS.FETCH_VEZEETA_CONFIG.LOAD,
        },
      };

    case CONFIGS.FETCH_VEZEETA_CONFIG.SUCCEEDED:
      return {
        ...state,
        vezeetaConfig: {
          configKey: payload.vezeetaConfigObj.configKey,
          url: payload.vezeetaConfigObj.url,
          branchAuth: payload.vezeetaConfigObj.branchAuth,
          state: CONFIGS.FETCH_VEZEETA_CONFIG.SUCCEEDED,
        },
      };

    case CONFIGS.FETCH_VEZEETA_CONFIG.FAILED:
      return {
        ...state,
        vezeetaConfig: {
          configKey: undefined,
          url: undefined,
          branchAuth: [],
          state: CONFIGS.FETCH_VEZEETA_CONFIG.FAILED,
        },
      };

    case CONFIGS.CHANGE_BRANCH_VEZEETA_CONFIG_TOKEN.LOAD:
      return {
        ...state,
        regerenatedToken: {
          branchKey: undefined,
          auth: undefined,
          state: CONFIGS.CHANGE_BRANCH_VEZEETA_CONFIG_TOKEN.LOAD,
        },
      };

    case CONFIGS.CHANGE_BRANCH_VEZEETA_CONFIG_TOKEN.SUCCEEDED:
      return {
        ...state,
        regerenatedToken: {
          branchKey: payload.vezeetaConfigObj.branchKey,
          auth: payload.vezeetaConfigObj.auth,
          state: CONFIGS.CHANGE_BRANCH_VEZEETA_CONFIG_TOKEN.SUCCEEDED,
        },
        regenerateTokenModal: { show: false, tokenDetails: {} },
      };

    case CONFIGS.CHANGE_BRANCH_VEZEETA_CONFIG_TOKEN.FAILED:
      return {
        ...state,
        regerenatedToken: {
          branchKey: undefined,
          auth: undefined,
          state: CONFIGS.CHANGE_BRANCH_VEZEETA_CONFIG_TOKEN.FAILED,
        },
      };

    case CONFIGS.CHANGE_BRANCH_VEZEETA_CONFIG_TOKEN.CHANGE:
      return {
        ...state,
        vezeetaConfig: {
          ...state.vezeetaConfig,
          state: CONFIGS.CHANGE_BRANCH_VEZEETA_CONFIG_TOKEN.CHANGE,
        },
      };

    case CONFIGS.ADD_EDIT_VEZEETA_CONFIG.LOAD:
      return {
        ...state,
        vezeetaConfig: {
          configKey: undefined,
          url: undefined,
          state: CONFIGS.ADD_EDIT_VEZEETA_CONFIG.LOAD,
        },
      };

    case CONFIGS.ADD_EDIT_VEZEETA_CONFIG.SUCCEEDED:
      return {
        ...state,
        vezeetaConfig: {
          configKey: payload.vezeetaConfigObj.configKey,
          url: payload.vezeetaConfigObj.url,
          state: CONFIGS.ADD_EDIT_VEZEETA_CONFIG.SUCCEEDED,
        },
      };

    case CONFIGS.ADD_EDIT_VEZEETA_CONFIG.FAILED:
      return {
        ...state,
        vezeetaConfig: {
          configKey: undefined,
          url: undefined,
          state: CONFIGS.ADD_EDIT_VEZEETA_CONFIG.FAILED,
        },
      };

    case CONFIGS.ADD_EDIT_USER_CONFIG.LOAD:
      return {
        ...state,
        addEditUserConfig: {
          configKey: undefined,
          url: undefined,
          authToken: undefined,
          branchKeys: [],
          state: CONFIGS.ADD_EDIT_USER_CONFIG.LOAD,
        },
      };

    case CONFIGS.ADD_EDIT_USER_CONFIG.SUCCEEDED:
      return {
        ...state,
        addEditUserConfig: {
          configKey: payload.userConfigObj.configKey,
          url: payload.userConfigObj.url,
          authToken: payload.userConfigObj.authToken,
          branchKeys: payload.userConfigObj.branchKeys,
          state: CONFIGS.ADD_EDIT_USER_CONFIG.SUCCEEDED,
        },
        addUserConfigModal: { show: false },
      };

    case CONFIGS.ADD_EDIT_USER_CONFIG.FAILED:
      return {
        ...state,
        addEditUserConfig: {
          configKey: undefined,
          url: undefined,
          authToken: undefined,
          branchKeys: [],
          state: CONFIGS.ADD_EDIT_USER_CONFIG.FAILED,
        },
      };

    case CONFIGS.FETCH_USER_CONFIGS.LOAD:
      return {
        ...state,
        userConfigs: {
          list: [],
          filteredList: [],
          state: CONFIGS.FETCH_USER_CONFIGS.LOAD,
        },
      };

    case CONFIGS.FETCH_USER_CONFIGS.SUCCEEDED:
      return {
        ...state,
        userConfigs: {
          list: payload.userConfigs,
          filteredList: payload.userConfigs,
          state: CONFIGS.FETCH_USER_CONFIGS.SUCCEEDED,
        },
      };

    case CONFIGS.FETCH_USER_CONFIGS.FAILED:
      return {
        ...state,
        userConfigs: {
          list: [],
          filteredList: [],
          state: CONFIGS.FETCH_USER_CONFIGS.FAILED,
        },
      };

    case CONFIGS.FETCH_CONFIGS.LOAD:
      return {
        ...state,
        configs: {
          list: [],
          filteredList: [],
          state: CONFIGS.FETCH_CONFIGS.LOAD,
        },
      };

    case CONFIGS.FETCH_CONFIGS.SUCCEEDED:
      return {
        ...state,
        configs: {
          list: payload.configs,
          filteredList: payload.configs,
          state: CONFIGS.FETCH_CONFIGS.SUCCEEDED,
        },
      };

    case CONFIGS.FETCH_CONFIGS.FAILED:
      return {
        ...state,
        configs: {
          list: [],
          filteredList: [],
          state: CONFIGS.FETCH_CONFIGS.FAILED,
        },
      };

    case CONFIGS.MODAL.TOGGLE_REGENERTE_TOKEN:
      return {
        ...state,
        regenerateTokenModal: {
          show: payload.showModal,
          tokenDetails: {},
        },
      };

    case CONFIGS.MODAL.TOGGLE_ADD_USER_CONFIG:
      return {
        ...state,
        addUserConfigModal: { show: payload.showModal },
      };

    default:
      return state;
  }
};
