import { ENTITIES } from '../actions/entities';

const initialState = {
  entities: {
    list: [],
    filteredList: [],
    state: undefined,
  },
  entityStructure: {
    Rooms: [],
    Branches: [],
    Entity: undefined,
    state: undefined,
  },
};

export default (state = initialState, { type, ...payload }) => {
  switch (type) {
    case ENTITIES.ENTITIES.LOAD:
      return {
        ...state,
        entities: {
          list: [],
          filteredList: [],
          state: ENTITIES.ENTITIES.LOAD,
        },
      };

    case ENTITIES.ENTITIES.LOAD_DONE: {
      return {
        ...state,
        entities: {
          list: payload.entities,
          filteredList: payload.entities,
          state: ENTITIES.ENTITIES.LOAD_DONE,
        },
      };
    }

    case ENTITIES.ENTITIES.LOAD_FAIL:
      return {
        ...state,
        entities: {
          list: [],
          filteredList: [],
          state: ENTITIES.ENTITIES.LOAD_FAIL,
        },
      };

    case ENTITIES.ENTITY_STRUCTURE.LOAD:
      return {
        ...state,
        entityStructure: {
          Rooms: [],
          Branches: [],
          Entity: {},
          state: ENTITIES.ENTITY_STRUCTURE.LOAD,
        },
      };

    case ENTITIES.ENTITY_STRUCTURE.LOAD_DONE:
      return {
        ...state,
        entityStructure: {
          Rooms: payload.entityStructure.Rooms,
          Branches: payload.entityStructure.Branches,
          Entity: payload.entityStructure.Clinic,
          state: ENTITIES.ENTITY_STRUCTURE.LOAD_DONE,
        },
      };

    case ENTITIES.ENTITY_STRUCTURE.LOAD_FAIL:
      return {
        ...state,
        entityStructure: {
          Rooms: [],
          Branches: [],
          Entity: {},
          state: ENTITIES.ENTITY_STRUCTURE.LOAD_FAIL,
        },
      };

    default:
      return state;
  }
};
