import { USERS } from '../actions/users';

const initialState = {
  addUser: {
    state: undefined,
  },
  userDetails: {
    email: undefined,
    entityKey: undefined,
    countryId: undefined,
    branchKeys: [],
    state: undefined,
  },
  users: {
    list: [],
    filteredList: [],
    state: undefined,
  },
  addUserModal: { show: false },
};

export default (state = initialState, { type, ...payload }) => {
  switch (type) {
    case USERS.ADD_USER.LOAD:
      return {
        ...state,
        addUser: {
          state: USERS.ADD_USER.LOAD,
        },
      };

    case USERS.ADD_USER.SUCCEEDED:
      return {
        ...state,
        addUser: {
          state: USERS.ADD_USER.SUCCEEDED,
        },
      };

    case USERS.ADD_USER.FAILED:
      return {
        ...state,
        addUser: {
          state: USERS.ADD_USER.FAILED,
        },
      };

    case USERS.FETCH_USER_DETAILS.LOAD:
      return {
        ...state,
        userDetails: {
          email: undefined,
          entityKey: undefined,
          countryId: undefined,
          branchKeys: [],
          state: USERS.FETCH_USER_DETAILS.LOAD,
        },
      };

    case USERS.FETCH_USER_DETAILS.SUCCEEDED:
      return {
        ...state,
        userDetails: {
          email: payload.userDetails.email,
          entityKey: payload.userDetails.entityKey,
          countryId: payload.userDetails.countryId,
          branchKeys: payload.userDetails.branchKeys,
          state: USERS.FETCH_USER_DETAILS.SUCCEEDED,
        },
      };

    case USERS.FETCH_USER_DETAILS.FAILED:
      return {
        ...state,
        userDetails: {
          email: undefined,
          entityKey: undefined,
          branchKeys: [],
          state: USERS.FETCH_USER_DETAILS.FAILED,
        },
      };

    case USERS.FETCH_USERS.LOAD:
      return {
        ...state,
        users: {
          list: [],
          filteredList: [],
          state: USERS.FETCH_USERS.LOAD,
        },
      };

    case USERS.FETCH_USERS.SUCCEEDED:
      return {
        ...state,
        users: {
          list: payload.users,
          filteredList: payload.users,
          state: USERS.FETCH_USERS.SUCCEEDED,
        },
      };

    case USERS.FETCH_USERS.FAILED:
      return {
        ...state,
        users: {
          list: [],
          filteredList: [],
          state: USERS.FETCH_USERS.FAILED,
        },
      };

    case USERS.MODAL.TOGGLE_ADD:
      return {
        ...state,
        addUserModal: { show: payload.showModal },
      };

    default:
      return state;
  }
};
